import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// top level locales
import enLocales from './en';
import deLocales from './de';
import frLocales from './fr';
import mxLocales from './mx';
import cuLocales from './cu';
import hiLocales from './hi';

// ----------------------------------------------------------------------

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {translations: enLocales},
            de: {translations: deLocales},
            fr: {translations: frLocales},
            mx: {translations: mxLocales},
            cu: {translations: cuLocales},
            hi: {translations: hiLocales}
        },
        lng: localStorage.getItem('i18nextLng') || 'en',
        fallbackLng: 'en',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
